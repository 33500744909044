<template>
  <div class="flex">
    <input
      v-for="position in 6"
      :ref="`input-${position}`"
      :key="position"
      v-form-control
      :value="codeMap[position]"
      :disabled="disabled"
      type="text"
      maxlength="isAndroidChrome ? 7 - position : 1"
      :inputmode="isAndroidChrome ? 'text' : 'numeric'"
      pattern="[0-9]*"
      class="mr-2 h-16 w-12 text-center text-4xl"
      @keydown.left.prevent="handleLeft(position)"
      @keydown.right.prevent="handleRight(position)"
      @keydown.backspace="handleBackspace(position, $event)"
      @paste.prevent="handlePaste(position, $event)"
      @input.prevent="handleChange(position, $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['codeEntered'],
  data() {
    return {
      codeMap: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      },
    };
  },
  computed: {
    code() {
      return Object.values(this.codeMap).join('');
    },
    isAndroidChrome() {
      return /Android.*Chrome\/[.0-9]*/.test(window.navigator.userAgent);
    },
  },
  mounted() {
    this.focusInputAtPosition(1);
  },
  methods: {
    reset() {
      this.codeMap = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      };

      this.$nextTick(() => {
        this.focusInputAtPosition(1);
      });
    },
    handleRight(position) {
      if (position === 6) return;

      this.focusInputAtPosition(position + 1);
    },
    handleLeft(position) {
      if (position === 1) return;

      this.focusInputAtPosition(position - 1);
    },
    focusInputAtPosition(position) {
      this.$refs[`input-${position}`][0].focus();

      // Set the cursor at the end of the input
      this.$refs[`input-${position}`][0].setSelectionRange(1, 1);
    },
    handleBackspace(position, $event) {
      if (position === 1) return;

      // If the input is already empty,
      // Clear and focus on the preceding input
      if ($event.target.value === '') {
        this.codeMap[position - 1] = '';
        this.focusInputAtPosition(position - 1);
      }
    },
    handlePaste(position, $event) {
      this.updateCodeMap(position, $event.clipboardData.getData('text/plain'));
    },
    handleChange(position, $event) {
      this.updateCodeMap(position, $event.target.value);
    },
    updateCodeMap(position, value) {
      if (value === '') {
        this.codeMap[position] = '';
        return;
      }

      // Since `value` can contain more than one character in the case of a paste,
      // We will fill up to the number of characters that will fit in the remaining inputs
      const numCharactersToFill = Math.min(value.length, 7 - position);
      for (let i = 0; i < numCharactersToFill; i++) {
        this.codeMap[position + i] = value.charAt(i);
      }

      const nextOrFinalInputPosition = Math.min(
        6,
        position + numCharactersToFill,
      );
      this.focusInputAtPosition(nextOrFinalInputPosition);

      if (this.code.length === 6) {
        this.$emit('codeEntered', this.code);
      }
    },
  },
};
</script>
